<template>
  <div>
    <TopNav />
    <SideNav />
    <router-view></router-view>
  </div>
</template>
<script>
import TopNav from '@/components/navigators/Topnav.vue';
import SideNav from '@/components/navigators/Sidenav.vue';
// import Footer from '@/components/navigators/Footer.vue';

export default {
  components: { 
      SideNav, 
      TopNav,
      // Footer
  }
}
</script>